import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Setup = () => (
  <Layout>
    <SEO title="WordTarget Setup" />
    <div className="pure-u-1-1 about">
      <h1>WordTarget Setup</h1>

      <p>
        For WordTarget to track your word count across apps, it needs an accessibility setting enabled. The way to do this differs depending upon the version of Mac OS X you're using - WordTarget works on 10.8 (Mountain Lion), 10.9 (Mavericks) and 10.10 (Yosemite).
      </p>

      <p>
        <strong>N.B.</strong> WordTarget should run on the more recent versions of macOS, and we're in the process of updating these instructions. Any problems with those versions in the meantime though, <a href="/contact">get in touch!</a>
      </p>

      <p>
        You can jump to the relevant instructions for your operating system below, and there is a screencast showing the setup process for each operating system also.
      </p>

      <br />

      <ul>
        <li><a href="#10.10">Mac OS X 10.10 Yosemite</a></li>
        <li><a href="#10.9">Mac OS X 10.9 Mavericks</a></li>
        <li><a href="#10.8">Mac OS X 10.8 Mountain Lion</a></li>
      </ul>

      <p>
        <strong>N.B.</strong> Please note, if you've upgraded your version of WordTarget recently through the Mac App Store, and the app is no longer tracking your words, you may need to run through these steps again to re-confirm permissions for the app before normal functionality is restored.
      </p>

      <br />

      <p>
        <a name="10.10"></a>
      </p>

      <h3>Mac OS X 10.10 Yosemite</h3>

      <p>
        1. Running the application should trigger a popup asking you to grant the permissions that WordTarget needs. Click "Open System Preferences". If you don't get this notification, then you can access the same panel by clicking the Apple icon in the top-left of your screen, selecting "System Preferences", clicking the "Security & Privacy" icon in the top row, and then clicking "Privacy", the last tab of the four along the top.
        <br />
        2. Click the lock in the bottom left and enter your admin password to be able to make changes.
        <br />
        3. In the right hand list, WordTarget should already be listed. If it isn't, then find WordTarget in your Finder (should be installed in Applications), and then drag it from your Finder in to this list. Once it's listed, ensure it has a check by its name.
        <br />
        4. Give it a couple of seconds and check back in the WordTarget menu, where it should have updated. If that doesn't update, try quitting and restarting WordTarget.
      </p>

      <br />

      <iframe width="960" height="720" src="//www.youtube.com/embed/7tAl-UxDLGA" frameborder="0" allowfullscreen></iframe>

      <p>
        <a name="10.9"></a>
      </p>

      <h3>Mac OS X 10.9 Mavericks</h3>

      <p>
        1. Click the Apple icon in the top-left of your screen, and select "System Preferences".
        <br />
        2. Click the "Security & Privacy" icon in the top row.
        <br />
        3. Click "Privacy", the last tab of the four along the top.
        <br />
        4. On the left hand list, select "Accessibility".
        <br />
        5. Click the lock in the bottom left and enter your admin password to be able to make changes.
        <br />
        6. In the right hand list, check to see if WordTarget is listed or not - if it isn't, then find WordTarget in your Finder (should be installed in Applications), and then drag it from your Finder in to this list. Once it's listed, ensure it has a check by its name. As seen in the video, sometimes the app won't be listed even after dragging it across, however so long as you've completed that step, it should still work.
        <br />
        7. Give it a couple of seconds and check back in the WordTarget menu, where it should have updated. If that doesn't update, try quitting and restarting WordTarget.
      </p>

      <br />

      <iframe width="960" height="720" src="//www.youtube.com/embed/WK4Xt_0I3VQ" frameborder="0" allowfullscreen></iframe>

      <p>
        <strong>N.B.</strong> This video shows an older version of WordTarget, however even though the app user interface has changed in the latest version, the setup steps are the same.
      </p>

      <br />

      <p>
        <a name="10.8"></a>
      </p>

      <h3>Mac OS X 10.8 Mountain Lion</h3>

      <p>
        1. Click the Apple icon in the top-left of your screen, and select "System Preferences".
        <br />
        2. Click the "Accessibility" icon in the "System" row.
        <br />
        3. Make sure the option towards the bottom of the pane is checked, "Enable access for assistive devices".
        <br />
        4. Give it a couple of seconds and check back in the WordTarget menu, where it should have updated. If that doesn't update, try quitting and restarting WordTarget.
      </p>

      <iframe width="960" height="720" src="//www.youtube.com/embed/IaP3gOAsIMM" frameborder="0" allowfullscreen></iframe>

      <p>
        <strong>N.B.</strong> This video shows an older version of WordTarget, however even though the app user interface has changed in the latest version, the setup steps themselves are the same.
      </p>
    </div>
  </Layout>
)

export default Setup
